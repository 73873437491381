import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    intersecting: Boolean
  }

  connect() {
    const observerOptions = {
      rootMargin: "-5% 0px -95% 0px"
    }

    this.observer = new IntersectionObserver((entries, observer) => {
      this.intersection(entries, observer)
    }, observerOptions)

    this.observer.observe(this.element)
  }

  disconnect() {
    if (this.observer) { this.observer.unobserve(this.element) }
  }

  intersection(entries, observer) {
    entries.forEach(entry => {
      this.intersectingValue = entry.isIntersecting
    })
  }

  intersectingValueChanged(value) {
    const location = document.location
    const hash = this.element.id
    const hashRegexp = new RegExp(hash)

    if (value && !hashRegexp.test(location.hash)) {
      this.update(`#${hash}`)
    } else if (!value && hashRegexp.test(location.hash)) {
      this.update(location.pathname)
    }
  }

  update(url) {
    history.replaceState(null, null, url)
  }
}

import "lazysizes"

import { Application } from "@hotwired/stimulus"
import PageController from "./js/controllers/page_controller"
import BodyController from "./js/controllers/body_controller"

window.Stimulus = Application.start()
Stimulus.register("body", BodyController)
Stimulus.register("page", PageController)

import "normalize.css/normalize.css"
import "./css/variables.css"
import "./css/typography.css"
import "./css/base.css"
import "./css/link.css"
import "./css/media.css"
import "./css/header.css"
import "./css/footer.css"

import { Controller } from "@hotwired/stimulus"
import throttle from "lodash/throttle"
import { scrollTo } from "./../shared/body"

export default class extends Controller {

  static targets = ["header", "scrollToTopBtn"]

  connect() {
    this.clickHandler = (event) => {
      const link = event.target.closest("a")

      if (link && location.hostname !== link.hostname) {
        event.preventDefault()
        window.open(link.href, "_blank")
      }

      if (link && link.hash && link.hostname == location.hostname && link.pathname == location.pathname) {

        const id = link.hash.split("#").slice(-1)[0]
        const target = document.getElementById(id)

        if (target) { 
          event.preventDefault()

          let offset = 0

          if (this.hasHeaderTarget) {
            const headerStyle = window.getComputedStyle(this.headerTarget)
            const headerMargins = parseInt(headerStyle.marginTop) + parseInt(headerStyle.marginBottom)
            offset = this.headerTarget.getBoundingClientRect().height + headerMargins
          }

          scrollTo(target, offset) 
        }
      }
    }

    this.element.addEventListener("click", this.clickHandler)

    this.scrollHandler = (event) => {
      const offset = 300
      const active = window.scrollY > offset ? true : false
      this.scrollToTopBtn(active)
    }

    this.scrollHandler(null);
    this.scrollHandlerThrottle = throttle(this.scrollHandler, 500)
    window.addEventListener("scroll", this.scrollHandlerThrottle)
  }

  disconnect() {
    this.element.removeEventListener("click", this.clickHandler)
    window.removeEventListener("scroll", this.scrollHandlerThrottle)
  }

  scrollToTopBtn(active) {
    if (active && !this.hasScrollToTopBtnTarget) {
      const element = document.createElement("button")
      element.classList.add("scroll-to-top-btn")
      element.setAttribute("data-body-target", "scrollToTopBtn")

      element.addEventListener("click", event => {
        event.preventDefault()
        scrollTo(document.body)
      })

      this.element.appendChild(element)
    } else if (!active && this.hasScrollToTopBtnTarget) {
      this.scrollToTopBtnTarget.parentNode.removeChild(this.scrollToTopBtnTarget)
    }
  }
}

function scrollTo(target, offset = 0) {
  const position = Number.isInteger(target) ? target : target.offsetTop

  window.scrollTo({
    top: position - offset,
    behavior: "smooth"
  })
}

export { scrollTo }
